.ant-table-cell:has(input) {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.ant-table-cell input {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.settings-billing h4,
.settings-billing h5 {
  margin-top: 0 !important;
}

.settings-billing .select-plan-dropdown .ant-select-selector {
  border-radius: 0 !important;
}
.add-tag-form .ant-select,
.add-tag-form input,
.add-tag-form .ant-select-selector {
  border-radius: 0 !important;
  border-color: #000 !important;
  height: 3rem;
}
.ql-editor{
  white-space: normal !important;
}
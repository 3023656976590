.version-control {
    padding: 16px;
}

.version-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.version-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.version-item {
    padding: 12px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.version-item:hover {
    background-color: #fafafa;
}

.version-item.current {
    border-color: #1890ff;
    background-color: #e6f7ff;
}

.version-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.version-number {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}

.version-date {
    font-size: 12px;
    color: #8c8c8c;
}

.version-actions {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
} 
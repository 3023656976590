@font-face {
  font-family: "PlusJakartaBold";
  src: url("./assets/fonts/PlusJakartaDisplay-Bold.ttf");
}
@font-face {
  font-family: "PlusJakartaMedium";
  src: url("./assets/fonts/PlusJakartaDisplay-Medium.ttf");
}
@font-face {
  font-family: "PlusJakarta";
  src: url("./assets/fonts/PlusJakartaDisplay-Regular.ttf");
}
body {
  margin: 0;
  font-family: "PlusJakarta", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

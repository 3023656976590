.App * {
  text-align: center;
  color: #0b0b45 !important;
}
.overflow-container {
  overflow-y: scroll;
  height: 77vh;
  padding: 1px;
}
.overflow-container::-webkit-scrollbar {
  width: 5px;
}

.overflow-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
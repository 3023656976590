.accordion-block {
    position: relative;
}

.accordion-block .ant-collapse {
    background: transparent;
}

.accordion-block .ant-collapse-item {
    margin-bottom: 8px;
}

.accordion-block .ant-collapse-header {
    padding: 12px 16px !important;
    background: #fafafa;
}

.accordion-block .ant-collapse-content {
    background: #fff;
}

.accordion-block .ant-input {
    margin-right: 8px;
} 
.ant-collapse-expand-icon{
    height: 48px!important;
}
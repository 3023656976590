.custom-editor .ProseMirror {
  outline: none !important;
  padding: 0;
}

.custom-editor .ProseMirror:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.custom-editor-content {
  height: 100%;
  min-height: 500px;
}

/* Basic styling for the editor content */
.custom-editor .ProseMirror p {
  margin: 0.5em 0;
}

.custom-editor .ProseMirror > * + * {
  margin-top: 0.75em;
} 

.editor-bubble-menu {
  display: flex;
  background: white;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
}

.editor-toolbar {
  display: flex;
  gap: 4px;
  align-items: center;
  transform-origin: top;
  transition: all 0.2s ease-in-out;
  margin-bottom: 8px;
}

.editor-toolbar:not(.editor-toolbar-visible) {
  margin: 0;
  padding: 0 !important;
}

.editor-toolbar-divider {
  width: 1px;
  height: 24px;
  background: #f0f0f0;
  margin: 0 4px;
}

/* Make sure the bubble menu appears above other elements */
.tippy-box {
  z-index: 1000;
}

/* Additional editor content styles */
.custom-editor .ProseMirror {
  padding: 16px;
}

.custom-editor .ProseMirror p {
  margin: 0.5em 0;
  line-height: 1.6;
}

.custom-editor .ProseMirror ul,
.custom-editor .ProseMirror ol {
  padding-left: 1.5em;
  margin: 0.5em 0;
}

.custom-editor .ProseMirror li {
  margin: 0.2em 0;
} 

.simple-editor {
    background: #fff;
}

.editor-toolbar {
    display: flex;
    gap: 4px;
    align-items: center;
}

.editor-content {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.5;
    transition: all 0.2s ease-in-out;
}

.editor-content:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.editor-content[contenteditable="true"]:empty:before {
    content: 'Start typing...';
    color: #bfbfbf;
    cursor: text;
}

/* Style for text formatting */
.editor-content b, .editor-content strong {
    font-weight: 600;
}

.editor-content i, .editor-content em {
    font-style: italic;
}

.editor-content u {
    text-decoration: underline;
}

.editor-content ul, .editor-content ol {
    padding-left: 24px;
    margin: 16px 0;
}

.editor-content ul li, .editor-content ol li {
    margin: 4px 0;
} 

.content-blocks {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
}

.block-wrapper {
    position: relative;
    /* padding: 16px; */
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background: #fff;
    transition: all 0.3s;
}

.block-wrapper:hover {
    border-color: #40a9ff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.block-controls {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s;
}

.block-wrapper:hover .block-controls {
    opacity: 1;
}

.block-move-controls {
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s;
}

.block-wrapper:hover .block-move-controls {
    opacity: 1;
}

.content-block {
    position: relative;
    /* padding-top: 24px; */
}

.accordion-block .ant-collapse {
    background: #fff;
    border: none;
}

.accordion-block .ant-collapse-item {
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid #f0f0f0;
}

.accordion-block .ant-collapse-header {
    background: #fafafa;
}

.block-toolbar {
    display: flex;
    gap: 8px;
}

.version-control {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.version-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.version-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.version-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    transition: all 0.3s;
}

.version-item:hover {
    background-color: #fafafa;
}

.version-item.current {
    border-color: #1890ff;
    background-color: #e6f7ff;
}

.version-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.version-number {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.version-date {
    font-size: 12px;
    color: #8c8c8c;
}

.version-actions {
    display: flex;
    gap: 8px;
}

.content-block {
    background-color: white;
}

.accordion-block .ant-collapse {
    background-color: white;
}

.accordion-block .ant-collapse-content {
    background-color: #fafafa;
}

.version-control {
    background-color: white;
}

.content-concerns {
    background-color: white;
}
 
.content-block {
    position: relative;
    /* padding: 16px; */
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    /* margin-bottom: 16px; */
}

.block-controls {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.content-block:hover .block-controls {
    opacity: 1;
}

.drag-handle {
    cursor: move;
} 
.quill-wrapper .ql-container {
    font-size: 16px;
}

.quill-wrapper .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.quill-wrapper.focused .ql-toolbar {
    display: block;
    opacity: 1;
}

/* .quill-wrapper .ql-editor {
    min-height: 150px;
} */

.quill-wrapper .ql-editor.ql-blank::before {
    font-style: normal;
    color: #999;
}

.ql-container.ql-snow {
    border: 1px solid #ccc!important;
}

/* Highlight tag styles */
.highlight-tag {
    display: inline-block;
    background-color: #e8f0fe;
    color: #1a73e8;
    border-radius: 12px;
    padding: 2px 8px;
    margin: 0 2px;
    font-size: 0.9em;
    line-height: 1.4;
    border: 1px solid #c6dafc;
}

.quill-container {
    position: relative;
    width: 100%;
}

.editor-wrapper, .viewer-wrapper {
    width: 100%;
}

.editor-wrapper .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.editor-wrapper .ql-container, .viewer-wrapper .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* min-height: 120px; */
}

.viewer-wrapper .ql-container {
    border: 1px solid #d9d9d9;
}

.viewer-wrapper .ql-editor {
    padding: 12px 15px;
}

.editor-wrapper.focused .ql-toolbar,
.editor-wrapper.focused .ql-container {
    border-color: #40a9ff;
}

.highlight-tag {
    border-radius: 2px;
    padding: 0 2px;
    margin: 0 1px;
}

.quill-container .ql-toolbar {
    /* visibility: hidden; */
    /* display: none; */
    /* opacity: 0; */
    transition: opacity 0.2s ease-in-out;
}

.quill-container.focused .ql-toolbar {
    /* visibility: visible; */
    display: block;
    opacity: 1;
}

.editor-container {
    position: relative;
    width: 100%;
}

.quill-container {
    position: absolute;
    width: 100%;
    z-index: 2;
    background: white;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.quill-container.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.quill-container.visible {
    opacity: 1;
    visibility: visible;
}

.highlight-view {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* min-height: 150px; */
    white-space: pre-wrap;
    font-family: inherit;
    line-height: 1.5;
    background: #f9f9f9;
    cursor: text;
}

.highlight-view.editing {
    visibility: hidden;
}

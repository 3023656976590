.content-blocks {
    padding: 16px;
}

.block-wrapper {
    position: relative;
    /* margin-bottom: 24px; */
}

.block-move-controls {
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.block-wrapper:hover .block-move-controls {
    opacity: 1;
} 